import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import toolsMappingApi from "../../tools/toolsMappingApi";
import toolsRegisterApi from "../../tools/toolsRegisterApi";
import { useNavigate } from "react-router-dom";
import { setGpc } from "../../features/gpcSlice";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import toolsExportApi from "../../tools/toolsExportApi";
import WorkspaceEditItems from "./WorkspaceEditItems";
import WorkspaceViewItems from "./WorkspaceViewItems";
import useToolsNotifications from "../../tools/ToolsNotifications";
import ExportIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Can } from "@casl/react";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import toolsAdminApi from "../../tools/toolsAdminApi";

export default function WorkspaceItems() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userAbility = useSelector((state) => state.userAbility.value);
    const gpc = useSelector((state) => state.gpc.value);
    const selectedDataModel = useSelector((state) => state.selectedDataModel.value);
    const {error, success} = useToolsNotifications();
    const { getDataModel, getMapping, getSourceItems } = toolsMappingApi();
    const { postExportSourceItem, deleteSourceItem } = toolsExportApi();
    const { postRegisterSourceItem } = toolsRegisterApi();
    const { deleteAllMappings: deleteAllMappingsCall, deleteAllProducts: deleteAllProductsCall } = toolsAdminApi();
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [dialogContent, setDialogContent] = React.useState('dialogContent');
    const [processedItems, setProcessedItems] = useState(undefined);
    const [visibleProcessedItems, setVisibleProcessedItems] = useState(undefined);
    const [dataModel, setDataModel] = useState([]);
    const [dataMapping, setMapping] = useState({});
    const [sourceItems, setSourceItems] = useState(undefined);
    const [visibleSourceItems, setVisibleSourceItems] = useState(undefined);
    const [selectedRows, setSelectedRows] = useState([]);
    const [editState, setEditState] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dialogActionsComponent, setDialogActionsComponent] = useState([])
    const [dialogTitle, setDialogTitle] = useState(undefined)


    const header = [{
        field: 'gtin',
        headerName: 'GTIN',
        minWidth: 170,
        align: 'left',
        renderHeader: () => (
            <Typography variant="tableHeaderLight">GTIN</Typography>
        ),
    },{
        field: 'functionalName',
        headerName: 'Product Name',
        minWidth: 270,
        align: 'left',
        renderHeader: () => (
            <Typography variant="tableHeaderLight">Product Name</Typography>
        ),
    },{
        field: 'gpcCategoryCode',
        headerName: 'GPC Code',
        minWidth: 170,
        align: 'left',
        renderHeader: () => (
            <Typography variant="tableHeaderLight">GPC Code</Typography>
        ),
    },{
        field: 'gpcDescription',
        headerName: 'GPC Desc',
        minWidth: 170,
        align: 'left',
        renderHeader: () => (
            <Typography variant="tableHeaderLight">GPC Desc</Typography>
        ),
    },{
        field: 'status',
        headerName: 'Product Status',
        minWidth: 170,
        align: 'left',
        renderHeader: () => (
            <Typography variant="tableHeaderLight">Product Status</Typography>
        ),
    },{
        field: 'mapped',
        headerName: 'Mapped',
        minWidth: 120,
        align: 'left',
        renderHeader: () => (
            <Typography variant="tableHeaderLight">Mapped</Typography>
        ),
    },{
        field: 'itemId',
        headerName: 'itemId',
        minWidth: 270,
        align: 'left',
        hide: true,
    }]

    useEffect(() => {
        if(visibleSourceItems !== undefined) {
            setLoading(false);
        }
    }, [visibleSourceItems])

    useEffect(() =>{
        if(processedItems !== undefined && gpc !== null) {
            setVisibleProcessedItems(processedItems.filter(function(element) { return element.gpcCategoryCode === gpc }));
        } else {
            setVisibleProcessedItems(processedItems);
        }
    }, [processedItems])

    useEffect(() => {
        if(selectedDataModel !== undefined && selectedDataModel.classification !== null && dataMapping !== undefined && Object.keys(dataMapping).length > 0) {
            const classificationTargetFieldId = selectedDataModel.classification;
            if(classificationTargetFieldId === undefined || classificationTargetFieldId === null || dataMapping[classificationTargetFieldId] === null || dataMapping[classificationTargetFieldId] === undefined) {
                alert('To use the Product Browser you first need to map "' + classificationTargetFieldId + '" field, as is the key field for this data model');
                navigate('/mapping')
                return; 
            }
        }
    }, [dataMapping])

    

    useEffect(() =>{
        if(selectedDataModel !== undefined && selectedDataModel.classification !== null && dataMapping[selectedDataModel.classification] !== null && dataMapping[selectedDataModel.classification] !== undefined && gpc !==null && gpc !==undefined) {
            const classificationTargetFieldId = selectedDataModel.classification;
            const classificationSrcFieldId = dataMapping[classificationTargetFieldId].value;
            const visibleItems = sourceItems.filter(function(element) { return element.item[classificationSrcFieldId] === gpc })

            setVisibleSourceItems(visibleItems);
        } else {
            setVisibleSourceItems(sourceItems);
        }
    }, [sourceItems])

    useEffect(() => {
        console.log(selectedDataModel)
        setLoading(true);
        if(selectedDataModel !== undefined && selectedDataModel.type !== undefined && selectedDataModel.version !== undefined) {
            getDataModel(selectedDataModel.type, selectedDataModel.version, gpc).then((data) => {
                if(!data.error) {
                    setDataModel(data.payload);
                }
            });

            getMapping(selectedDataModel.type, selectedDataModel.version, gpc).then((data) => {
                setMapping(data.payload);
            });

            getSourceItems({gpcCode: gpc}).then((data) => {
                setSourceItems(data.payload);
            });
        }
    },[selectedDataModel, gpc])

    // Generate human friendly message from the validations response
    // TODO: this should probably be in another place, maybe a tools file to manage validations
    const prepareErrors = (errors) => {
        let message = '';
        for(var gtin in errors) {
            // Message supports \n and \t characters
            message += gtin + ':\n\n\t'
            for(var i in errors[gtin]) {
                if(!errors[gtin][i].valid) {
                    message += '(' + errors[gtin][i].attribute + ') ' + errors[gtin][i].error + '\n\t'
                }
            }
        }
        return message
    }

    const showExportError = (text) => {
        let msg = "Error: " + text;
        error(msg);
    }

    const showExportSuccess = (n) => {
        let msg = n + " file(s) exported successfully";
        success(msg);
    }

    const showDeleteSuccess = (n) => {
        let msg = n + " file(s) deleted successfully";
        success(msg);
    }

    const exportRow = (id) => {
        if(selectedDataModel !== undefined) {
            postExportSourceItem(selectedDataModel.type, selectedDataModel.version, id).then((data) => { 
                if(data.error === true) {
                    var errors = prepareErrors(data.payload.detail)
                    showExportError("Validation Error")
                    setDialogTitle("Validation errors");
                    setDialogContent(errors)
                    setDialogActionsComponent([<Button onClick={handleClose}>Ok</Button>]);
                    setOpen(true);
                    setScroll('paper');
                } else {
                    // Creating a Blob for having a csv file format
                    // and passing the data with type
                    const blob = new Blob([data.payload], { type: 'text/xml' });
                
                    // Creating an object for downloading url
                    const url = window.URL.createObjectURL(blob)
                
                    // Creating an anchor(a) tag of HTML
                    const a = document.createElement('a')
                
                    // Passing the blob downloading url
                    a.setAttribute('href', url)
                
                    // Setting the anchor tag attribute for downloading
                    // and passing the download file name
                    a.setAttribute('download', id + 'download.xml')
                
                    // Performing a download with click
                    a.click()

                    // Remove the created button
                    //document.removeChild(a)
                    showExportSuccess();
                }
            }).catch(function(error) {                        // catch
                console.log(error)
                showExportError("Server failed")
            });
        }
    }

    const registerRow = (id) => {
        if(selectedDataModel !== undefined) {
            postRegisterSourceItem(selectedDataModel.type, selectedDataModel.version, id).then((data) => { 
                if(data.error === true) {
                    var errors = prepareErrors(data.payload.detail)
                    showExportError("Validation Error")
                    setDialogTitle("Validation errors");
                    setDialogContent(errors)
                    setDialogActionsComponent([<Button onClick={handleClose}>Ok</Button>]);
                    setOpen(true);
                    setScroll('paper');
                } else {
                    // Creating a Blob for having a csv file format
                    // and passing the data with type
                    const blob = new Blob([data.payload], { type: 'text/xml' });
                
                    // Creating an object for downloading url
                    const url = window.URL.createObjectURL(blob)
                
                    // Creating an anchor(a) tag of HTML
                    const a = document.createElement('a')
                
                    // Passing the blob downloading url
                    a.setAttribute('href', url)
                
                    // Setting the anchor tag attribute for downloading
                    // and passing the download file name
                    a.setAttribute('download', id + 'download.xml')
                
                    // Performing a download with click
                    a.click()

                    // Remove the created button
                    //document.removeChild(a)
                    showExportSuccess();
                }
            }).catch(function(error) {                        // catch
                console.log(error)
                showExportError("Server failed")
            });
        }
    }

    const deleteRow = (id) => {
        deleteSourceItem(id).then((data) => { 
            if(!data.error) {
                if(data.payload === 'ok') {
                    // Remove item from list
                    let items = [ ...processedItems]
                    for(var i in items) {
                        if(items[i].id === id) {
                            items.splice(i,1)
                            setProcessedItems(items)
                            break
                        }
                    }
                }
            }
        },)
        
    }

    const processMapping = (mappingData) => {
        let processed = {};

        for(const key in mappingData) {
            if(mappingData[key] !== null) {
                processed[mappingData[key]] = key;
            }
        }
        return mappingData;
    }

    const processItems = (items) => {
        let processed = [];
        let mappingProcessed = processMapping(dataMapping)
        let index = 0;

        items.map((item) => {
            let gpcDescription = "";
            const classificationTargetFieldId = selectedDataModel.classification;
            if(classificationTargetFieldId !== null && classificationTargetFieldId !== undefined && dataMapping[classificationTargetFieldId] !== null && dataMapping[classificationTargetFieldId] !== undefined) {
                const classificationSrcFieldId = dataMapping[classificationTargetFieldId].value;                
                const gpc = selectedDataModel.gpcs.find(element => element.brick === item.item[classificationSrcFieldId]);
                gpcDescription = gpc !== undefined ? gpc.brickName !== undefined ? gpc.brickName : '' : '';
            }
            let itemProcessed = {}
            for(const i in header) {
                itemProcessed[header[i].field] = '';
            }
            let mapped = 0;
            for(const i in dataModel) {
                if(mappingProcessed[dataModel[i].fieldId] !== undefined) {
                    var fieldId = dataModel[i].fieldId
                    if(typeof fieldId === 'number') {
                        fieldId = fieldId.toString()
                    }
                    itemProcessed[dataModel[i].name] = item.item[mappingProcessed[fieldId].value]
                    mapped++;
                }
            }
            itemProcessed['status'] = item['status']
            
            // if(selectedDataModel.key && mappingProcessed[selectedDataModel.key] && item.item[mappingProcessed[selectedDataModel.key]]) {
            //     itemProcessed['gtin'] = item.item[mappingProcessed[selectedDataModel.key]]
            // } else {
            //     itemProcessed['gtin'] = ''
            // }

            

            itemProcessed['id'] = item['id'];
            itemProcessed['gpcDescription'] = gpcDescription;
            itemProcessed['mapped'] = mapped + '/' + Object.entries(dataModel).length
            index++;

            processed.push(itemProcessed)
        })
        setProcessedItems(processed)
    }

    useEffect(() => {
        if(sourceItems !== undefined){
            processItems(sourceItems)
        }
    },[dataModel,sourceItems])

    const handleOnRowClick = (e) => {
        console.log(e)

        let targetUrl = "/mapping/" + e.row[e.columns[1].field];
        navigate(targetUrl);
    }

    const handleClose = () => {
        setOpen(false);
    };
    const descriptionElementRef = React.createRef();
    const batchExport = () => {
        if(selectedRows.length > 0) {
            selectedRows.map((id) => {
                exportRow(id);
            })
            showExportSuccess(selectedRows.length)
            setSelectedRows([]);
        } else {
            showExportError("Select at least 1 row to export")
        }
    }

    const batchRegister = () => {
        selectedRows.map((id) => {
            registerRow(id)
        })
    }

    const batchDelete = () => {
        if(selectedRows.length > 0) {
            selectedRows.map((id) => {
                deleteRow(id);
            })
            showDeleteSuccess(selectedRows.length);
            setSelectedRows([]);
        } else {
            showExportError("Select at least 1 row to delete")
        }
    }

    const showDeleteAllProductsSuccess = () => {
        setOpen(false);
        navigate('/mapping');
        success("All products deleted successfully.");
    }

    const showDeleteAllProductsError = () => {
        setOpen(false);
        error("An error happened deleting all products.");
    }

    const deleteAllProducts = () => {
        deleteAllProductsCall().then(data => {
            if(!data.error){
                showDeleteAllProductsSuccess();
            } else {
                showDeleteAllProductsError();
            }
        })
    }

    const deleteAllProductsDialog = () => {
        setDialogTitle("Delete All Products");
        setDialogContent("Are you sure you want to delete all the products in the database?")
        setDialogActionsComponent([ <Button fullWidth onClick={deleteAllProducts}>Yes</Button>,
                                    <Button fullWidth onClick={handleClose}>No</Button>]);
        setOpen(true);
        setScroll('paper');
    }

    const showDeleteAllMappingsSuccess = () => {
        setOpen(false);
        navigate('/mapping');
        success("All mappings for data model " + selectedDataModel.type + " " + selectedDataModel.version + " deleted successfully.");
    }

    const showDeleteAllMappingsError = () => {
        setOpen(false);
        error("An error happened deleting mappings for data model " + selectedDataModel.type + " " + selectedDataModel.version + ".");
    }

    const deleteAllMappings = () => {
        deleteAllMappingsCall(selectedDataModel.type, selectedDataModel.version).then(data => {
            if(!data.error){
                showDeleteAllMappingsSuccess();
            } else {
                showDeleteAllMappingsError();
            }
        })
    }

    const deleteAllMappingsDialog = () => {
        setDialogTitle("Delete All Mappings");
        setDialogContent("Are you sure you want to delete all the mappings for the data model " + selectedDataModel.type + " " + selectedDataModel.version + "?")
        setDialogActionsComponent([ <Button fullWidth onClick={deleteAllMappings}>Yes</Button>,
                                    <Button fullWidth onClick={handleClose}>No</Button>])
        setOpen(true);
        setScroll('paper');
    }

    const handleEdit = () => {
        setEditState(!editState);
    }

    const updateSourceItem = (itemId, fieldId, newValue) => {
        const copySI = {...sourceItems}
        sourceItems.map((item, index) => {
            if(item.id === itemId) {
                copySI[index].item[fieldId] = newValue
            }
        })
    }

    let mainStyle = {
        marginTop: "20px",
        marginBottom: "20px"
    };
    const buttonStyle = {
        backgroundColor: "#FFF",
        border: "solid 1px #1EB1E0",
        color: "#3284C6",
        fontSize: "12px",
        fontWeight: "400",
        '&:hover': {
            backgroundColor: "rgba(30, 177, 224, 0.1)"
        },
        padding: '5px 30px 5px 16px'
    }

    const buttonDeleteStyle = {
        backgroundColor: "#FFF",
        border: "solid 1px #F72424",
        color: "#F72424",
        fontSize: "12px",
        fontWeight: "400",
        '&:hover': {
            backgroundColor: "rgba(247, 36, 36, 0.1)"
        },
        padding: '5px 30px 5px 16px'
    }

    const buttonStyleActive = {
        backgroundColor: "#3284C6",
        border: "solid 1px #1EB1E0",
        color: "#FFF",
        fontSize: "12px",
        fontWeight: "400",
        '&:hover': {
            color: "#3284C6",
            backgroundColor: "rgba(30, 177, 224, 0.1)"
        },
        padding: '5px 30px 5px 16px'
    }
    const buttonIcon = {marginRight: '20px'}
    
    return  <Grid item xs={12} height="calc(100% - 112px)" rowSpacing={2}>
                <Grid style={mainStyle} item container direction="row" columnSpacing={1}>
                    <Grid item container xs={6} columnSpacing={1}>
                        <Grid item >
                            <Button 
                                sx={buttonStyle} 
                                onClick={batchExport}
                                startIcon={<ExportIcon />}
                                disabled={editState}
                            >
                                Export
                            </Button>
                        </Grid>
                        {/* {!editState ? 
                            <Grid item >
                                <Button sx={buttonStyle} onClick={batchRegister}>Register</Button>
                            </Grid> 
                        : ''} */}
                        
                        <Grid item >
                            <Button 
                                sx={buttonStyle} 
                                onClick={batchDelete}
                                startIcon={<DeleteIcon />}
                                disabled={editState}
                            >
                                Delete
                            </Button>
                        </Grid>
                        {userAbility !== undefined ?
                            <Grid item > 
                                <Can I="deleteAll" this={'Products'} ability={userAbility}>
                                    <Button 
                                        sx={buttonDeleteStyle} 
                                        onClick={deleteAllProductsDialog}
                                        startIcon={<HighlightOffIcon />}
                                        disabled={editState}
                                    >
                                        Delete All Products
                                    </Button>
                                </Can>
                            </Grid>
                        :
                            ''
                        }
                        {userAbility !== undefined ?
                            <Grid item > 
                                <Can I="deleteAll" this={'Mappings'} ability={userAbility}>
                                    <Button 
                                        sx={buttonDeleteStyle} 
                                        onClick={deleteAllMappingsDialog}
                                        startIcon={<HighlightOffIcon />}
                                        disabled={editState}
                                    >
                                        Delete All Mappings
                                    </Button>
                                </Can>
                            </Grid>
                        :
                            ''
                        }
                    </Grid>
                    <Grid item container xs={6} columnSpacing={1} justifyContent="flex-end">
                        <Grid item >
                            {selectedDataModel !== undefined && selectedDataModel.key !== null && dataMapping[selectedDataModel.key] !== undefined ?
                                <Button 
                                    sx={editState ? buttonStyleActive : buttonStyle} 
                                    onClick={() => handleEdit()}
                                    startIcon={<EditIcon />}
                                >
                                    Edit Mode
                                </Button>
                                :
                                ''
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {editState ? 
                        <WorkspaceEditItems mapping={dataMapping} dataModel={dataModel} sourceItems={visibleSourceItems} updateSourceItem={updateSourceItem} />
                    :
                        <WorkspaceViewItems loading={loading} handleOnRowClick={handleOnRowClick} setSelectionModel={setSelectedRows} selectionModel={selectedRows} mapping={dataMapping} dataModel={dataModel} sourceItems={visibleSourceItems} />
                        
                        
                }
                {/* <ProductTable 
                            loading={loading}
                            selectionModel={selectedRows}
                            setSelectionModel={setSelectedRows}
                            rows={visibleProcessedItems !== undefined ? visibleProcessedItems : []} 
                            header={header} 
                            rowCount={visibleProcessedItems !== undefined ? visibleProcessedItems.length : 0} 
                            handleOnRowClick={handleOnRowClick} 
                        /> */} 
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    maxWidth={'lg'}
                >
                    <DialogTitle id="scroll-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Typography
                        variant="body1"
                        style={{whiteSpace: 'pre-wrap'}}
                        >
                        {dialogContent}
                        </Typography>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {dialogActionsComponent.map(elem => (
                            elem
                        ))}
                    </DialogActions>
                </Dialog>
            </Grid>;
}
